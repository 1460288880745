.ui-notification {
  position: fixed;
  z-index: 9999;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  width: 300px;
  color: @btn-primary-color;
  background: @brand-primary;
  .transition(all ease 0.5s);
  &.killed {
    opacity: 0;
    .transition(opacity ease 1s);
  }
  & > h3 {
    display: block;
    margin: 10px 10px 0 10px;
    padding: 0 0 5px 0;
    text-align: left;
    font-size: @font-size-base;
    font-weight: bold;
    border-bottom: 1px solid fadeout(@btn-primary-color, 70%);
  }
  & a {
    color: @btn-primary-color;
    &:hover {
      text-decoration: underline;
    }
  }
  & > .message {
    margin: 10px 10px 10px 10px;
  }
  &.warning {
    color: @btn-warning-color;
    background: @brand-warning;
  }
  &.error {
    color: @btn-danger-color;
    background: @brand-danger;
  }
  &.success {
    color: @btn-success-color;
    background: @brand-success;
  }
  &.info {
    color: @btn-info-color;
    background: @brand-info;
  }
  &:hover {
    opacity: 0.7;
  }
}
