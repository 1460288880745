.icon_counter {
  color                 : #fff;
  font-family           : "Arial";
  font-size             : 11px;
  font-weight           : 600;

  position              : absolute;

  padding               : 0px 2px;
  margin-top            : -0.1em;
  margin-left           : 1em;
  -webkit-border-radius : 3px;
  -moz-border-radius    : 3px;
  border-radius         : 3px;

  -webkit-box-shadow    : inset 0px 0px 2px 0px rgba(50, 50, 50, 0.75);
  -moz-box-shadow       : inset 0px 0px 2px 0px rgba(50, 50, 50, 0.75);
  box-shadow            : inset 0px 0px 2px 0px rgba(50, 50, 50, 0.75);

  background-color      : #f00;


}

//.nav {
//  padding: 5px;
//}

