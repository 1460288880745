@import "vendor/bootstrap/less/bootstrap.less";
@import "vendor/font-awesome/less/font-awesome.less";
@import "vendor/smalot-bootstrap-datetimepicker/less/datetimepicker.less";
@import "vendor/summernote/src/less/summernote.less";
@import "vendor/summernote/src/less/elements.less";
@import "vendor/bootstrap-sweetalert/lib/sweet-alert.less";

@import "ui-notification.less";
@import "login.less";
@import "iconcounter.less";
@import (inline) "../css/select2/dist/css/select2.css";
@import "vendor/select2-bootstrap-theme/src/select2-bootstrap.less";
@import "themes/cerulean/variables.less";
@import "modaleffect.less";


@btn-font-weight: 300;
@font-family-sans-serif: "Roboto", Helvetica, Arial, sans-serif;
@fa-font-path:   "/fonts/fontawesome/fonts";

body, label, .checkbox label {
	font-weight: 300;
}

body  {
  padding-top: 4em;
  //background: url('/images/knitting250px.png');
}

.carousel-caption {
  background-color: #444;
  //width: 100%;
  opacity: 0.7;
  width: auto;
  margin-bottom: 5px;
  //left: 2px;
  //right: 2px;
  //bottom: 0px;
}

.carousel-indicators {
  display: none;
}

.carousel-inner img {
  min-height: 226px;
  max-height: 230px;
  width: 100%;
  //height: auto;
}

.carousel-inner .active.left { left: -33%; }
.carousel-inner .next        { left:  33%; }
.carousel-inner .prev        { left: -33%; }
.carousel-control.left,.carousel-control.right {background-image:none;}
.carousel-control {
  padding-top:10%;
  width:5%;
}


#feedly-mini {
  display: none;
}

.select2.select2-container {
    width: 100% !important;
}

.summernote {
  height: 20em !important;
}

tr.tx-clickable-row {
    &:hover {
      cursor: pointer;
      background-color: @badge-active-color;
    }
}

.qrScanner {
  position: absolute;
  top: 50%;
}
#qrscanner select {
    .form-control;
}

[ng\:cloak], [ng-cloak], .ng-cloak {
  display: none !important;
}

.modal-header h6 {
  padding: .4em 1em;
  background-color: @brand-primary;
  color: #fff;
  font-size: 20px !important;
}

.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

.select2-search__field {
           width: 100% !important;
}

img.prod_preview {
  position: absolute;
  bottom: .4em;
  right:.4em;
  //border: 2px solid @brand-primary;;
  display: none;
  z-index: 9999;
}

table.table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50%;
}

#history {
  table {
    table-layout: fixed;
  }

  .div-table-content {
    height:150px;
    overflow-y:auto;
  }
}

table.table thead td, table.table tfoot td {
  font-weight: bold;
}

.form_datetime {
  width: 8em !important;
}

#scanModal .modal-body {
  padding: -5px 20px !important;
}


//#txModal  {
//  max-height: 100vh !important;
//  overflow-y: auto;
//}

#txModal .modal-body {
  max-height: calc(100vh - 150px) !important;
  min-height: 260px !important;
  overflow-y: auto;
}



.table-nowrap {
  table-layout:fixed;
}

.table-nowrap td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}
 .card {
   min-height: 324px;
 }

 .card-img-top {
   //min-height: 220px !important;
   max-height: 130px !important;
 }

 #order_cart {
    position: fixed;
    right: 4em;
  }